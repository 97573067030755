.slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px 0;
  outline: 0;

  &:hover {
    cursor: pointer;
  }
}

.slider__bar {
  width: 100%;
  height: 4px;
  background-color: $lightGray;
  outline: 0;
}

.slider__bar__fill {
  position: relative;
  height: 100%;
  background-color: rgb(255, 0, 80);
}

.slider__handle {
  position: absolute;
  top: -5px;
  right: -6px;
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid $lighterGray;
  outline: 0;
}
