.promotion {
  user-select: none;
  width: 100%;

  .promotion-row {
    position: relative;
    ::-webkit-scrollbar {
      display: none;
    }

    $smallMargin: 20px;
    $largeMargin: 80px;

    @media screen and (max-width: $break-medium) {
      margin-left: $smallMargin;
    }
    @media screen and (min-width: $break-medium) {
      margin-left: $largeMargin;
    }

    .navigate {
      border-radius: 25px;
      background-color: white;
      font-size: 40px;
      cursor: pointer;
      position: absolute;
    }

    .navigate-next {
      @media screen and (max-width: $break-medium) {
        right: 0px;
      }
      @media screen and (min-width: $break-medium) {
        right: 60px;
      }
      bottom: 160px;
    }

    .navigate-prev {
      left: -20px;
      bottom: 160px;
    }

    margin-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    .promotion-top {
      width: 94%;
      display: flex;
      justify-content: space-between;
      .title {
        color: white;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 12px;
      }
      .see-more {
        color: white;
        background: #585858;
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 12px;
        margin-top: 0px;
        padding: 4px 10px 0 10px;
        border-radius: 5px;
      }
    }
    .cards-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      overflow: auto;
      scrollbar-width: none;
      box-sizing: border-box;

      @media screen and (max-width: $break-medium) {
        width: calc(100% - #{$smallMargin});
      }
      @media screen and (min-width: $break-medium) {
        width: calc(100% - #{$largeMargin});
      }

      .promotion-card {
        margin-right: 15px;
        img {
          width: 200px;
          height: 200px;
          border-radius: 5px;
        }
        .text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
        }
        .song-artist {
          color: white;
          margin-bottom: 0;
          margin-top: 8px;
        }
        .song-title {
          color: #8b9096;
          margin-top: 0;
          margin-bottom: 0;
        }
        .song-lang {
          color: #8b9096;
          margin-top: 0;
        }
      }
    }
  }
}
