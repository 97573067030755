.song-card {
  @extend %paper;
  cursor: pointer;
  margin: 1em;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  user-select: none;
  color: white;

  .description-column {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    flex-basis: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      margin-top: 0;
      margin-bottom: 0.3em;
    }
  }

  img {
    @media screen and (max-width: $break-medium) {
      height: 5rem;
      width: 5rem;
    }
    @media screen and (min-width: $break-medium) {
      height: 7rem;
      width: 7rem;
    }
    margin-right: 10px;
    border-radius: 5px;
  }
}

.filter-box {
  margin: 20px;
}
