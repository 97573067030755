.song-detail {
  display: flex;
  flex-direction: column;
  height: 93vh;

  .song-content {
    scroll-behavior: smooth;
    min-height: 0;
    flex-grow: 1;
    overflow-y: scroll;

    .song-info {
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 0.75em;
      .song-meta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 0;
        p {
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis
        }
      }
      .song-image {
        @media screen and (max-width: $break-medium) {
          height: 6rem;
          width:6rem;
        }
        @media screen and (min-width: $break-medium) {
          height: 7rem;
          width: 7rem;
        }
        border-radius: 5px;
      }
    }

    .lyric-container {
      @extend %paper;
      color: $secondaryTextColor;
      padding: 30px 0;
      text-align: center;
      span {
        margin: 40px;
        p {
          margin: 0px;
        }
      }
      margin-bottom: 134px;
      .translate-icon {
        position: absolute;
        top: 30%;
        left: 10%;
        cursor: pointer;
      }
    }
  }
}
