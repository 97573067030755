$primaryColor: #17212D;
$secondaryColor: #FC3769;
$primaryTextColor: white;
$secondaryTextColor: #8B9096;


$black: #222;
$lightBlack: #6d6d6d;
$lighterBlack: #999;

$blue: #3381b7;
$lightBlue: lighten($blue, 5);
$darkBlue: darken($blue, 5);

$lightestGray: #fafafa;
$lighterGray: #e3e3e3;
$lightGray: #ddd;
$gray: #ccc;
$mediumGray: #c8c9cb;
$darkGray: #adadad;
$darkerGray: #3a3f41;
$darkestGray: #2B2B2B;

$orange: #f85d0f;
$darkOrange: darken($orange, 5);
$darkerOrange: darken($orange, 10);

$lightGreen: #a6d2a5;
$green: #7ec57c;
$darkGreen: darken($green, 5);
$darkerGreen: darken($green, 10);

$lighterRed: #F9C5C5;
$lightRed: darken($lighterRed, 5);
$red: #e17d74;
$darkRed: darken($red, 5);
$darkerRed: darken($red, 10);

$white: #fff;

%paper {
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px 0px rgba(0,0,0,0.12);
  border-radius: 4px;
  background-color: $primaryColor;
}


$break-small: 400px;
$break-medium: 640px;
$break-large: 1200px;
