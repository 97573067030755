.player {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0 10px 0;
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 134px;
  background: white;
  border-radius: 20px 20px 0 0;

  .indeterminate {
    margin-bottom: 20px;
    height: 4px;
  }

  .time-container {
    width: 100%;
    @media only screen and (min-width: $break-medium) {
      width: 60%;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .slider {
      flex-basis: 60%;
    }
    span {
      flex-basis: 15%;
      user-select: none;
      color: rgb(60, 60, 60);
    }
    margin-top: 10px;
  }

  .button-container {
    width: 80%;
    @media only screen and (min-width: $break-medium) {
      width: 40%;
    }
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .player-song-info {
    text-overflow: ellipsis;
    white-space: nowrap;
    p {
      font-weight: bold;
      color: rgb(60, 60, 60);
      margin-top: -15px;
      margin-bottom: 0px;
    }
  }
}
