.contact {
  background-color: white;
  height: 91vh;
  h1 {
    font-weight: initial;
    color: black;
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  .email-field, .text-field, .captcha-field  {
    width: 30rem;
    max-width: 80%;
  }
  .captcha {
    display: flex;
    align-items: center;

    img {
      height: 56px;
      margin: 10px;
    }
  }
}


.message-sent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* color: #6f6f6f; */
  height: 70vh;
}
