@import "variables.scss";
@import "songlist.scss";
@import "songdetail.scss";
@import "player.scss";
@import "slider.scss";
@import "contact.scss";
@import "tutorial.scss";
@import "form-dialog.scss";
@import "promotions.scss";
@import "search.scss";


@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('/static/iransans/eot/IRANSansWeb_Bold.eot');
  src: url('/static/iransans/eot/IRANSansWeb_Bold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('/static/iransans/woff2/IRANSansWeb_Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('/static/iransans/woff/IRANSansWeb_Bold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('/static/iransans/ttf/IRANSansWeb_Bold.ttf') format('truetype');
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/static/iransans/eot/IRANSansWeb_Medium.eot');
  src: url('/static/iransans/eot/IRANSansWeb_Medium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('/static/iransans/woff2/IRANSansWeb_Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('/static/iransans/woff/IRANSansWeb_Medium.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('/static/iransans/ttf/IRANSansWeb_Medium.ttf') format('truetype');
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/static/iransans/eot/IRANSansWeb_Light.eot');
  src: url('/static/iransans/eot/IRANSansWeb_Light.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('/static/iransans/woff2/IRANSansWeb_Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('/static/iransans/woff/IRANSansWeb_Light.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('/static/iransans/ttf/IRANSansWeb_Light.ttf') format('truetype');
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('/static/iransans/eot/IRANSansWeb_UltraLight.eot');
  src: url('/static/iransans/eot/IRANSansWeb_UltraLight.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('/static/iransans/woff2/IRANSansWeb_UltraLight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('/static/iransans/woff/IRANSansWeb_UltraLight.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('/static/iransans/ttf/IRANSansWeb_UltraLight.ttf') format('truetype');
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/static/iransans/eot/IRANSansWeb.eot');
  src: url('/static/iransans/eot/IRANSansWeb.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('/static/iransans/woff2/IRANSansWeb.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('/static/iransans/woff/IRANSansWeb.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('/static/iransans/ttf/IRANSansWeb.ttf') format('truetype');
}

body {
  margin: 0;
  background-color: $primaryColor !important;
  font-family: 'IRANSans', Arial, sans-serif;
  font-weight: normal;
  overflow-y: scroll;
  @media only screen and (max-width: $break-small) {
    font-size: 12px;
  }
  @media only screen and (min-width: $break-medium) {
    font-size: 15px;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

a {
  text-decoration: none;
}

.center {
  text-align: center;
}

.pointer {
  cursor: pointer
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.list-item {
  color: white !important,
}


.grey-item {
  color: rgb(60, 60, 60);
}
