.tutorial {
  margin: 0 auto;
  color: $primaryTextColor;
  @media only screen and (min-width: $break-medium) {
    width: 70%;
  }
  @media only screen and (min-width: $break-large) {
    width: 50%;
  }
  @media only screen and (max-width: $break-small) {
    width: 100%;
  }

  padding: 50px;
  .tutorial-text {
    text-align: justify;
  }
  img {
    max-width: 100%;
  }
}
